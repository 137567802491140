import { Component } from 'react';

import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import Team from './components/Team';
import Contact from './components/Contact';
import Labs from './components/Labs';
import Footer from './components/Footer';
import Carousels from './components/Carousels';

export default class App extends Component{
    componentDidMount() {
        const script8 = document.createElement('script');
        script8.src = './assets/js/main.js';
        document.body.appendChild(script8);
    }
    
    render() {
        return (
            <div className="App">
                <Header/>
                <Home/>

                <main id="main">
                    <Carousels/>    
                    <About/>
                    <Services/>
                    <Portfolio/>
                    <Team/>
                    <Contact/>
                    <Labs/>
                </main>


                <Footer/>

                <div id="preloader"></div>
                <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
            </div>
        );
    }
}
