import React, { Component } from "react"
import Carousel from 'react-bootstrap/Carousel';
import '../App.css'

export default class Carousels extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <section id="carousel" className="set-padding">
                <Carousel className="my-carousel" controls={false} slide={true} >
                    <Carousel.Item interval={4000}>
                        <img
                            className="img-size"
                            src='assets/img/carousel/carousel01.jpg'
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3>First slide label</h3>
                            <p>
                                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                            </p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <img
                            className="img-size"
                            src='assets/img/carousel/carousel02.jpg'
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3>Second slide label</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>

                {/* <Carousel className="my-carousel" nextIcon={this.state.nextIcon} prevIcon={this.state.prevIcon}>
                    <Carousel.Item interval={1000}>
                        <div className="d-flex">
                            <img
                                className="img-multi-size"
                                src={apple}
                                alt="First slide"
                            />

                            <img
                                className="img-multi-size"
                                src={banana}
                                alt="First slide"
                            />
                        </div>

                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="d-flex">
                            <img
                                className="img-multi-size"
                                src={banana}
                                alt="First slide"
                            />

                            <img
                                className="img-multi-size"
                                src={apple}
                                alt="First slide"
                            />
                        </div>
                    </Carousel.Item>
                </Carousel> */}
                {/* <a href="#one" className="goto-next scrolly">Next</a> */}
            </section>
        )
    }

}