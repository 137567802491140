import React, { Component } from 'react';

class Home extends Component {
    render() {
        return (
            <>
                <section id="hero" className="d-flex align-items-center justify-content-center">
                    <div className="container" data-aos="fade-up">

                        <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
                            <div className="col-xl-6 col-lg-8">
                                <h1>해양안전 구조 플랫폼 J&M<span>.</span></h1>
                                <h2>J&M CORPORATION</h2>
                            </div>
                        </div>

                        <div className="row gy-4 mt-5 justify-content-center" data-aos="zoom-in" data-aos-delay="250">
                            <div className="col-xl-2 col-md-4">
                                <div className="icon-box">
                                    <i className="ri-store-line"></i>
                                    <h3><a href="">자가조정형 장비</a></h3>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4">
                                <div className="icon-box">
                                    <i className="ri-bar-chart-box-line"></i>
                                    <h3><a href="">자가조정형 부력 발생장치</a></h3>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4">
                                <div className="icon-box">
                                    <i className="ri-calendar-todo-line"></i>
                                    <h3><a href="">보트 케어</a></h3>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4">
                                <div className="icon-box">
                                    <i className="ri-paint-brush-line"></i>
                                    <h3><a href="">해양 레저</a></h3>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4">
                                <div className="icon-box">
                                    <i className="ri-database-2-line"></i>
                                    <h3><a href="">시설</a></h3>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </>
        );
    }
}

export default Home;