import React, { Component } from 'react';

class Header extends Component {
    render() {
        return (
            <>
                <header id="header" className="fixed-top header-scrolled">
                    <div className="container d-flex align-items-center justify-content-lg-between">

                        <h1 className="logo me-auto me-lg-0"><a href="#hero">J&M<span>.</span></a></h1>


                        <nav id="navbar" className="navbar order-last order-lg-0">
                            <ul>
                                {/* <li><a className="nav-link scrollto active" href="#hero">홈</a></li> */}
                                <li className="dropdown"><a className="nav-link scrollto" href="#about">회사소개</a>
                                    <ul>
                                        <li><a href="#">회사소개 및 사업방향</a></li>
                                        <li><a href="#">특허/지적재산권</a></li>
                                    </ul>
                                </li>
                                
                                <li><a className="nav-link scrollto" href="#services">시스템 개발</a></li>
                                <li><a className="nav-link scrollto " href="#portfolio">포트폴리오</a></li>
                                <li><a className="nav-link scrollto" href="#team">팀 소개</a></li>
                                <li><a className="nav-link scrollto" href="#contact">문의하기</a></li>
                                <li><a className="nav-link scrollto" href="#labs">연구소</a></li>
                            </ul>
                            <i className="bi bi-list mobile-nav-toggle"></i>
                        </nav>

                        <a href="#about" className="get-started-btn scrollto">Get Started</a>

                    </div>
                </header>
            </>
        );
    }
}

export default Header;